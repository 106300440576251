import moment from 'moment';

export const formatDate = (date: Date | string, format: string = 'YYYY-MM-DD'): string => {
  return moment.utc(date).format(format);
};

export const formatTime = (date: Date | string, format: string = 'HH:mm'): string => {
  return moment.utc(date).format(format);
};

export const formatDateTime = (date: Date | string, format: string = 'YYYY-MM-DD HH:mm'): string => {
  return moment.utc(date).format(format);
};

export const getDuration = (start: Date | string, end: Date | string): string => {
  const duration = moment.duration(moment(end).diff(moment(start)));
  return `${duration.asHours().toFixed(2)} hrs`;
};

export const isValidDate = (date: any): boolean => {
  return moment.utc(date).isValid();
};

export const addDays = (date: Date | string, days: number): Date => {
  return moment.utc(date).add(days, 'days').toDate();
};

export const subtractDays = (date: Date | string, days: number): Date => {
  return moment.utc(date).subtract(days, 'days').toDate();
};