import React from 'react';
import { Plus } from 'lucide-react';

interface FloatingActionButtonProps {
  onClick: () => void;
  visible: boolean;
  className?: string;
  text? : string;
  pulse?: boolean
}

const FloatingActionButton: React.FC<FloatingActionButtonProps> = ({ text, onClick, visible, className, pulse = false }) => {
  return (
    <button
      onClick={onClick}
      className={`${pulse && 'animate-pulse-custom'}  fixed p-2 bg-brand-lightgray text-brand-darkgray ${text ? 'rounded px-4': 'rounded-full'}
                 hover:bg-brand-lightgray/90 transition-colors z-40 ${visible ? '' : 'hidden'}
                 flex items-center justify-center ${className}`}
      aria-label="Add Activity" >
      <Plus className={text ? 'mr-2 w-5 h-5': 'w-6 h-6'} />
      {text}
    </button>
  );
};

export default FloatingActionButton;