import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import HttpSrvc from '../HttpSrvc';
import StorageSrvc from '../StorageSrvc';
import { STORAGE_SRVC_KEYS } from '../../config/constants/AppStrings';

export const QUERIES = {
  //todo: FIX ENDPOINT
  GET_RSVP_STATUS_BY_EVENT_ID: (itineraryId: string) => useQuery({
    queryKey: [RSVP_CONSTANTS.QUERY_KEYS.RSVP_STATUS],
    queryFn: () => {
      return  HttpSrvc.get(`${RSVP_CONSTANTS.GET_RSVP_STATUS_BY_EVENT_ID_API}/${itineraryId}`);
    },
    enabled: !!itineraryId
  })

}

export const MUTATIONS = {
  RESPOND_RSVP: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, payload }: any) => {
      return HttpSrvc.put(`${RSVP_CONSTANTS.RESPOND_RSVP_API}/${eventId}`, payload)
    },
    onSuccess: (data: any) => {
      queryClient?.invalidateQueries({ queryKey: [RSVP_CONSTANTS.QUERY_KEYS.RSVP_STATUS] });
    },
    onError: (error: any) => {
      queryClient?.invalidateQueries({ queryKey: [RSVP_CONSTANTS.QUERY_KEYS.RSVP_STATUS] });
    }
  }),
}

export const RSVP_CONSTANTS = {
  QUERY_KEYS: {
    RSVP_STATUS: 'RSVP_STATUS'
  },
  RESPOND_RSVP_API: `/api/rsvp/respond`,
  GET_RSVP_STATUS_BY_EVENT_ID_API: `/api/rsvp/event/self`
}
