import { MUTATIONS as AUTH_MUTATIONS, QUERIES as AUTH_QUERIES } from "./AuthApi";
import { MUTATIONS as RSVP_MUTATIONS, QUERIES as RSVP_QUERIES } from "./RsvpApi";
import { MUTATIONS as OPEN_MUTATIONS, QUERIES as OPEN_QUERIES } from "./OpenApi";
import { MUTATIONS as PROGRAM_MUTATIONS } from "./ProgramApi";
import { MUTATIONS as ACTIVITY_MUTATIONS } from "./ActivityApi";
import { MUTATIONS as EVENT_MUTATIONS, QUERIES as EVENT_QUERIES } from "./EventApi";


export default {
  QUERIES: {
    AUTH: AUTH_QUERIES,
    RSVP: RSVP_QUERIES,
    OPEN: OPEN_QUERIES,
    EVENT: EVENT_QUERIES
  },

  MUTATIONS: {
    AUTH: AUTH_MUTATIONS,
    RSVP: RSVP_MUTATIONS,
    OPEN: OPEN_MUTATIONS,
    PROGRAM: PROGRAM_MUTATIONS,
    ACTIVITY: ACTIVITY_MUTATIONS,
    EVENT: EVENT_MUTATIONS
  }
}