import React from 'react';

const Footer: React.FC = () => {
  return (
    <div className="flex flex-row p-2 pt-0 shadow-xl bg-brand-darkgray text-brand-danger w-96 self-center text-neutral-content mt-2 mb-2">
      <div className="mx-auto">
        <a className="link link-hover text-center text-brand-lightgray text-sm" href="/contact-us">
          Contact us
        </a>
      </div>
      <div className="mx-auto">
        <a className="link link-hover text-brand-lightgray text-sm" href="/privacy-policy">
          Privacy Policy
        </a>
      </div>
      <div className="mx-auto">
        <a className="link link-hover text-brand-lightgray text-sm" href="/terms-of-service">
          Terms of Service
        </a>
      </div>
    </div>
  );
};

export default Footer;