import React, { useContext, useEffect, useRef, useState } from 'react';
import { Camera, Clock } from 'lucide-react';
import { motion } from 'framer-motion';
import moment from 'moment';
import { EventViewMode } from '../events/EventView';
import { useQueryClient } from '@tanstack/react-query';
import QuerySrvc from '../../services/queries/QuerySrvc';
import { ToastContext } from '../Contexts';

interface ActivityCardProps {
  activity: any;
  selectedActivityId: string | null;
  onElementClick: (activity: any) => void;
  mode: EventViewMode;
}

const ActivityCard: React.FC<ActivityCardProps> = ({
  activity,
  selectedActivityId,
  onElementClick,
  mode
}) => {


  const queryClient = useQueryClient();
  const toast = useContext(ToastContext);
  const [dragging, setDragging] = useState(false);
  const uploadImageMutation = QuerySrvc.MUTATIONS.ACTIVITY.UPLOAD_ACTIVITY_IMAGE(queryClient);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (uploadImageMutation.status === 'success') {
      toast?.showSuccessToast('Image uploaded successfully');
    } else if (uploadImageMutation.status === 'error') {
      toast?.showErrorToast('Failed to upload image');
    }
  }, [uploadImageMutation.status]);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !activity.eventId) return;

    uploadImageMutation.mutate({
      eventId: activity.eventId,
      dayId: activity.programId,
      activityId: activity.id,
      file
    });
    fileInputRef.current && (fileInputRef.current.value = '');
  };

  const handleDragUpload = (e: React.DragEvent<HTMLDivElement>, activityId: string) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files?.[0];
    if (!file || !activity.eventId) return;

    uploadImageMutation.mutate({
      eventId: activity.eventId,
      dayId: activity.programId,
      activityId: activity.id,
      file
    });
  }

  const triggerFileInput = (e: React.MouseEvent, activityId: string) => {
    e.stopPropagation();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ duration: 0.5 }}
      className={`${selectedActivityId === activity.id ? 'h-full' : 'h-52'}`}
    >
      <div
        className={`relative flex flex-1 max-w-fit ${selectedActivityId === activity.id ? 'h-full' : 'h-52'} 
          ${dragging ? 'border-brand-lightgray' : 'border-brand-fadegray'} border-2 
          transition-all aspect-video rounded overflow-hidden `}
        onClick={() => onElementClick(activity)}
        onDragOver={(e) => {
          e.preventDefault();
          if (mode !== EventViewMode.MANAGE) {
            return;
          }
          setDragging(true);
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          if (mode !== EventViewMode.MANAGE) {
            return;
          }

          setDragging(false);
        }}
        onDrop={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (mode !== EventViewMode.MANAGE) {
            return;
          }
          setDragging(false);
          handleDragUpload(e as any, activity.id);
        }}
      >
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          accept="image/*"
          onChange={(e) => {
            selectedActivityId && handleImageUpload(e)
          }} />
        <div className={`relative flex flex-1 w-full h-full ${activity.image ? 'justify-end' : 'justify-center'}`}>
          {

            activity.image &&
            <img
              src={activity.image}
              className={`absolute w-full h-full object-cover ${selectedActivityId === activity.id ? '' : ''}`} />}
          {
            mode === EventViewMode.MANAGE && (uploadImageMutation.status === 'pending' && uploadImageMutation.variables?.activityId === activity.id &&
              <div className="w-full h-full bg-gradient-to-r from-brand-darkgray/0 via-brand-lightgray/30 to-brand-darkgray/0 flex items-center justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-brand-lightgray"></div>
              </div> ||
              <Camera className={`w-6 h-6 mb-4 mr-4 drop-shadow-contrast-black ${activity.image ? 'self-end' : 'self-center'} cursor-pointer z-40`}
                onClick={(e) => {
                  e.stopPropagation();
                  triggerFileInput(e, activity.id)
                }} />
            )
          }

        </div>
        <div className="top-0 absolute flex flex-col w-full h-full p-4 bg-brand-darkgray/10 content-between">
          <div className="flex items-center text-lg text-brand-lightgray font-bold flex-row">
            <div className='flex-auto text-brand-lightgray drop-shadow-contrast-black self-start cursor-default'>
              {activity.name}
            </div>
            <div className='text-sm text-brand-lightgray text-right'>
              <div className={`flex items-center justify-end px-2`}>
                <span className={`flex items-center inline-block whitespace-nowrap self-end drop-shadow-contrast-black cursor-default`}>
                  <Clock className="w-4 h-4 pr-1" />
                  {`${parseFloat(moment.duration(moment.utc(activity.endDateTime).diff(moment.utc(activity.startDateTime))).asHours().toFixed(2))} hrs`}
                </span>
              </div>
            </div>
          </div>
          <h3 className="flex flex-1 font-semibold text-lg text-brand-lightgray drop-shadow-contrast-black items-end cursor-default	">
            {activity.subtitle}
          </h3>
        </div>
      </div>
    </motion.div>
  );
};

export default ActivityCard;