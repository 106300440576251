import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import NavTabs from './NavTabs';
import Breadcrumbs from './Breadcrumbs';
import ManageEventsList from './ManageEventsList';
import EventView, { EventViewMode } from '../events/EventView';
import { useQuery } from '@tanstack/react-query';
import ApiService from '../../utils/api';

type Tab = 'Events' | 'Teams' | 'CRM';

const tabOptionsMap: { [key: string]: Tab } = {
  "events": "Events",
  "teams": "Teams",
  "crm": "CRM"
};

interface Event {
  id: string;
  name: string;
}

const Manage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { eventId } = useParams<{ eventId?: string }>();
  const [activeTab, setActiveTab] = useState<Tab>('Events');

  useEffect(() => {
    const path = location.pathname.split('/')[2] as Tab;
    if (path && ['events', 'teams', 'crm'].includes(path.toLowerCase())) {
      setActiveTab(tabOptionsMap[path.toLowerCase()]);
    }
  }, [location]);

  const { data: event } = useQuery<Event>({
    queryKey: ['event', eventId],
    queryFn: () => ApiService.get(`/api/events/${eventId}`),
    enabled: !!eventId
  });

  const handleBreadcrumbClick = (path: string) => {
    if (path === 'events') {
      navigate('/manage/events');
    }
  };


  const renderContent = () => {
    if (activeTab === 'Events' && eventId) {
      return <EventView mode={EventViewMode.MANAGE} />;
    }

    switch (activeTab.toLowerCase()) {
      case 'events':
        return  <ManageEventsList />;
      case 'teams':
        return <div className="text-brand-lightgray p-4">Teams feature coming soon</div>;
      case 'crm':
        return <div className="text-brand-lightgray p-4">CRM feature coming soon</div>;
      default:
        return null;
    }
  };


  return (
    <div className="flex flex-1 bg-brand-darkgray overflow-y-auto custom-scroll">
      <div className="flex flex-col flex-1">
        <Breadcrumbs
          activeTab={activeTab}
          eventName={event?.name}
          onBreadcrumbClick={handleBreadcrumbClick}
        />
        <div className='flex flex-1 flex-col overflow-auto'>
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default Manage;