import { QueryClient, useMutation } from '@tanstack/react-query';
import HttpSrvc from '../HttpSrvc';

export const MUTATIONS = {
  CREATE_ACTIVITY: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, dayId, formData }: { 
      eventId: string; 
      dayId: string; 
      formData: FormData 
    }) => {
      return HttpSrvc.postFormData(`/api/events/${eventId}/programs/${dayId}/activity`, formData);
    },
    onSuccess: (data: any) => {
      queryClient?.invalidateQueries({ queryKey: ["itineraryData", data.eventId] });
    }
  }),

  UPDATE_ACTIVITY: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, dayId, activityId, payload }: { 
      eventId: string; 
      dayId: string; 
      activityId: string; 
      payload: any 
    }) => {
      return HttpSrvc.put(`/api/events/${eventId}/programs/${dayId}/activity/${activityId}`, payload);
    },
    onSuccess: (data: any) => {
      queryClient?.invalidateQueries({ queryKey: ["itineraryData", data.eventId] });
    }
  }),

  UPLOAD_ACTIVITY_IMAGE: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, dayId, activityId, file }: { 
      eventId: string; 
      dayId: string; 
      activityId: string; 
      file: File 
    }) => {
      const formData = new FormData();
      formData.append('file', file);
      return HttpSrvc.postFormData(`/api/events/${eventId}/programs/${dayId}/activity/${activityId}/image`, formData);
    },
    onSuccess: (data: any) => {
      queryClient?.invalidateQueries({ queryKey: ["itineraryData", data.eventId] });
    }
  })
}