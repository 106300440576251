import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useState } from 'react'
import { Calendar, Clock, MapPin, ArrowLeft, Check, Ban, X, CircleCheck, CheckCheck, BadgeCheck, OctagonX } from 'lucide-react'
import { DayList } from './DayList';
import { ActivityList } from './ActivityList';
import { InclusionsList } from './InclusionsList';
import moment from 'moment';
import ActivityDetails from './ActivityDetails';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FetchItinerary } from './api/ItineraryApi';
import AuthPanel from '../login/component/AuthPanel';
import QuerySrvc from '../../services/queries/QuerySrvc';
import { ToastHandle } from '../Toast';
import { GlobalContext, ToastContext } from '../Contexts';
import { EventViewMode } from '../events/EventView';

interface ItineraryViewerProps {
  itineraryId?: string;
  mode?: EventViewMode
}

export default function ItineraryViewer({ itineraryId: propItineraryId, mode = EventViewMode.VIEW }: ItineraryViewerProps) {
  const queryClient = useQueryClient();
  const toast = useContext(ToastContext);
  const globalContext = useContext(GlobalContext);
  const { itineraryId: paramItineraryId } = useParams<{ itineraryId: string }>();

  // Use prop itineraryId if provided, otherwise fall back to URL param
  const effectiveItineraryId = propItineraryId || paramItineraryId;

  const { data: eventData, isSuccess, isLoading, isFetching, error } = useQuery({
    staleTime: 10000,
    queryKey: ["itineraryData", effectiveItineraryId],
    queryFn: () => {
      return effectiveItineraryId && FetchItinerary(effectiveItineraryId) || null;
    },
    enabled: !!effectiveItineraryId
  });

  const { data: eventUserRsvp, isSuccess: isRsvpStatusFetchSuccess, isError: isRsvpStatusFetchError,
    isFetching: isRsvpStatusFetching, refetch: refetchRsvpStatus } = QuerySrvc.QUERIES.RSVP.GET_RSVP_STATUS_BY_EVENT_ID(effectiveItineraryId!);

  const respondRsvpMutation = QuerySrvc.MUTATIONS.RSVP.RESPOND_RSVP(queryClient);

  const [selectedDayId, setSelectedDayId] = useState(eventData?.programs?.[0]?.id);
  const selectedDay = eventData?.programs.find((day: any) => day.id === selectedDayId);
  const [dayDescriptionExpanded, setDayDescriptionExpanded] = useState(false);

  const [selectedActivityId, setSelectedActivityId] = useState<string | null>(eventData?.programs?.[0]?.activities?.[0]?.id);
  const selectedActivity = selectedDay?.activities.find((activity: any) => activity.id === selectedActivityId);

  const [selectedColumn, setSelectedColumn] = useState(0);

  useEffect(() => {
    if (isSuccess) {
      setSelectedDayId(selectedDayId || eventData?.programs?.[0]?.id);
      setSelectedActivityId(selectedActivityId || eventData?.programs?.[0]?.activities?.[0]?.id);
    }
  }, [isFetching]);

  useEffect(() => {
    if (respondRsvpMutation.status === 'success') {
      const onSigninSuccess = async () => {
      }
      onSigninSuccess();
      globalContext.showDownloadAppBar();
    }
    else if (respondRsvpMutation.status === 'error') {
      toast.showErrorToast(respondRsvpMutation?.error?.response?.data?.error);
      globalContext.showDownloadAppBar();
    }
  }, [respondRsvpMutation.status]);

  const handleRsvp = (status: string) => {
    respondRsvpMutation.mutate({ eventId: effectiveItineraryId, payload: { status: status ? "Yes" : "No" } });
  }

  if (isLoading) return <div className='text-center'>Loading...</div>;
  if (error) return <div className='text-center'>Error loading data</div>;
  if (!eventData) return null;

  return (
    <div className="flex flex-1 relative h-full bg-brand-darkgray order-2">
      <div
        className="fixed inset-0 bg-cover bg-center bg-no-repeat z-10"
        style={{
          backgroundImage: `url(${selectedDay?.image || eventData.image})`,
          backgroundAttachment: 'fixed',
          opacity: 0.3
        }}
      >
        <div className="absolute inset-0 bg-brand-darkgray/70 backdrop-blur-sm"></div>
      </div>

      <div className="relative flex flex-1 flex-col h-full">
        {mode === EventViewMode.VIEW &&
          <>
            <header className="flex flex-row items-center justify-between p-2 sm:p-4 text-brand-lightgray flex z-40">
              {
                eventUserRsvp?.status !== "YES" && eventUserRsvp?.status !== "NO" && <div className='hidden sm:flex flex-1'></div>
              }
              <h1 className="flex flex-1 flex-col justify-center sm:flex-row text-center text-2xl sm:text-4xl font-normal ">
                <span className="grow">
                  {eventData.name}
                </span>
                {
                  eventUserRsvp?.status === 'YES' &&
                  <span className="flex flex-row text-brand-midgreen py-2 rounded w-28 px-4 border-brand-fadegray border-0 cursor-default relative
                      bg-gradient-to-r to-brand-darkgray via-brand-darkgray from-brand-lightgreen/10 text-sm items-center">
                    <span className='pr-2'>Attending </span>
                    <BadgeCheck className='absolute right-0 ' size={16} color="#1affe8" strokeWidth={0.75} absoluteStrokeWidth />
                  </span>
                }
                {
                  eventUserRsvp?.status === 'NP' &&
                  <span className="flex flex-row text-brand-danger py-2 rounded w-28 px-4 border-brand-fadegray border-0 cursor-default
                      bg-gradient-to-r to-brand-darkgray via-brand-darkgray from-brand-danger/10 text-sm items-center">
                    <span className='pr-2'>Declined</span>
                    <OctagonX size={16} color="#ef4444" strokeWidth={0.75} absoluteStrokeWidth />
                  </span>
                }
              </h1>
              {
                eventUserRsvp?.status !== "YES" && eventUserRsvp?.status !== "NO" &&
                <div className='flex flex-1 flex-row justify-center sm:justify-end gap-4 p-2 sm:p-4 z-40'>
                  <AuthPanel
                    content="Join"
                    className={`text-brand-lightgray py-2 rounded w-28 border-brand-fadegray border-2 hover:shadow-lightgray transition-all
                      bg-gradient-to-r from-brand-darkgray via-brand-darkgray to-brand-lightgreen/10`}
                    callback={() => {
                      respondRsvpMutation.mutate({ eventId: effectiveItineraryId, payload: { status: "Yes" } });
                    }}
                    errorCallback={() => { }}
                  />
                  <AuthPanel
                    content="Decline"
                    className={`text-brand-lightgray py-2 rounded w-28 border-brand-fadegray border-2 hover:shadow-fadegray transition-all 
                      bg-gradient-to-r from-brand-darkgray via-brand-darkgray to-brand-danger/10 `}
                    callback={() => {
                      respondRsvpMutation.mutate({ eventId: effectiveItineraryId, payload: { status: "No" } });
                    }}
                    errorCallback={() => { }}
                  />
                </div>
              }
            </header>

          </>
        }

        <div className="flex-1 flex gap-6 sm:py-0 overflow-hidden z-30 py-2">
          <div className={`w-full sm:w-4/12 backdrop-blur-md rounded-2xl overflow-y-hidden sm:flex
             flex-col ${selectedColumn === 0 ? 'flex' : 'hidden'}`}>
            <div className="flex flex-1 overflow-hidden p-0">
              <DayList
                onElementClick={(day: any) => {
                  setSelectedDayId(day.id);
                  setSelectedActivityId(day.activities?.[0]?.id);
                  setSelectedColumn(1)
                }}
                days={eventData.programs.sort((a: any, b: any) => a.dayIndex - b.dayIndex)}
                selectedDayId={selectedDayId}
                mode={mode}
              />
            </div>
          </div>

          <div className={`w-full sm:w-4/12  sm:flex flex-col ${selectedColumn === 1 ? 'flex' : 'hidden'} 
                backdrop-blur-md rounded-2xl overflow-hidden` }>
            <h2 className="text-xl font-semibold text-brand-lightgray mb-6 text-center px-4 sm:hidden">
              <ArrowLeft className='absolute left-0 cursor-pointer text-brand-lightgray ' onClick={() => { setSelectedColumn(Math.max(selectedColumn - 1, 0)) }} />
            </h2>

            <div className={`flex flex-1 overflow-y-auto custom-scroll pt-6 pb-4`}>
              <div className="flex flex-1 flex-col">
                <ActivityList
                  onElementClick={(activity: any) => {
                    setSelectedColumn(2);
                    setSelectedActivityId(activity.id)
                  }}
                  activities={selectedDay?.activities || []}
                  selectedActivityId={selectedActivityId}
                  selectedDay={selectedDay}
                  mode={mode}
                />
              </div>
            </div>
          </div>

          <div className={`w-full sm:w-4/12 bg-brand-darkgray/5 backdrop-blur-md rounded-2xl overflow-hidden flex-1 sm:flex flex-col ${selectedColumn === 2 ? 'flex' : 'hidden'}`}>
            <ActivityDetails activity={selectedActivity} mode={mode} />
            <ArrowLeft className='absolute left-0 cursor-pointer text-brand-lightgray sm:hidden' onClick={() => { setSelectedColumn(Math.max(selectedColumn - 1, 0)) }} />
          </div>
        </div>
      </div>
    </div>
  );
}