import React, { useState, useEffect, useCallback } from 'react';

interface TypeWriterProps {
  text: string;
  speed?: number;
  delay?: number;
  className?: string;
  onComplete?: () => void;
  showCursor?: boolean;
}

const TypeWriter: React.FC<TypeWriterProps> = ({
  text,
  speed = 50,
  delay = 0,
  className = '',
  onComplete,
  showCursor = true
}) => {
  const [displayText, setDisplayText] = useState('');
  const [isComplete, setIsComplete] = useState(false);

  const typeText = useCallback(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      if (currentIndex <= text.length) {
        setDisplayText(text.slice(0, currentIndex));
        currentIndex += 1;
      } else {
        clearInterval(intervalId);
        setIsComplete(true);
        onComplete?.();
      }
    }, speed);

    return () => clearInterval(intervalId);
  }, [text, speed, onComplete]);

  useEffect(() => {
    const timeoutId = setTimeout(typeText, delay);
    return () => clearTimeout(timeoutId);
  }, [delay, typeText]);

  return (
    <span className={className}>
      {displayText}
      {!isComplete && showCursor && (
        <span className="inline-block w-[2px] h-[1em] ml-0.5 align-middle bg-brand-lightgray animate-pulse">
        </span>
      )}
    </span>
  );
};

export default TypeWriter;