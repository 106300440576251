import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import CarbonpaperLogo from "./../images/carbonpaper-full-logo-new.png";
import CarbonpaperLogoOnly from "./../images/carbonpaper-logo.svg";
import { Menu } from 'lucide-react';

import QuerySrvc from '../services/queries/QuerySrvc';
import { getProfilePic, getUserNameOrEmail } from '../services/utils';
import { ChevronDown } from 'lucide-react';
import { useQueryClient } from '@tanstack/react-query';
import Toast, { ToastHandle } from './Toast';
import { ToastContext, GlobalContext, CurrentUserContext } from './Contexts';
import AppDownloadBanner from './AppDownloadBanner';
import AuthPanel from './login/component/AuthPanel';
import StorageSrvc from '../services/StorageSrvc';
import Drawer from './home/Drawer';

const Layout: React.FC = () => {
  const queryClient = useQueryClient();
  const logoutMutation = QuerySrvc.MUTATIONS.AUTH.LOGOUT(queryClient);
  const toastRef = useRef<ToastHandle>(null);
  const { currentUser } = useContext(CurrentUserContext);
  const [showDownloadAppbar, setShowDownloadAppbar] = React.useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const location = useLocation();


  useEffect(() => {
    const appDownloadBannerLastShown = StorageSrvc.getItem('appDownloadBannerLastShown');
    if (appDownloadBannerLastShown) {
      const timeDiff = new Date().getTime() - new Date(appDownloadBannerLastShown).getTime();

      if (timeDiff < 1000 * 60 * 60 * 24) {
        setShowDownloadAppbar(false);
      } else {
        setShowDownloadAppbar(true);
        StorageSrvc.setItem('appDownloadBannerLastShown', new Date().toISOString());
      }
    } else {
      setShowDownloadAppbar(true);
      StorageSrvc.setItem('appDownloadBannerLastShown', new Date().toISOString());
    }
  }, []);

  const showSuccessToast = (message: string) => {
    toastRef.current?.show(message, 'success');
  };

  const showErrorToast = (message: string) => {
    toastRef.current?.show(message, 'error');
  };

  const showDownloadAppBarMethod = () => {
    setShowDownloadAppbar(true);
  }

  const autoOpenAuthPanel = location.pathname !=="/" &&  !currentUser;

  const profilePicUrl = getProfilePic(currentUser?.profilePic)
  const username = getUserNameOrEmail(currentUser);

  return (
    <GlobalContext.Provider value={{ showDownloadAppBar: showDownloadAppBarMethod }}>
      <ToastContext.Provider value={{ showSuccessToast, showErrorToast }}>
        <div className='flex flex-col min-h-screen h-full bg-brand-darkgray'>
          <div className={`flex navbar bg-brand-darkgray sticky top-0 z-40 ${currentUser ? 'border-b-2' : ''} border-brand-fadegray`}>
            {currentUser && (
              <button 
                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                className="text-brand-lightgray p-2 rounded-lg hover:bg-brand-lightgray/10 transition-colors mr-2"
              >
                <Menu size={24} />
              </button>
            )}
            <div className="flex-1">
              {true && (
                <>
                  <a className="w-60 bg-brand-darkgray text-xl p-4 hidden md:flex" href='/'>
                    <img src={CarbonpaperLogo} alt="Carbon Paper Logo" className="w-full" />
                  </a>
                  <a className="w-60 bg-brand-darkgray text-xl px-4 flex md:hidden overflow-hidden" href='/'>
                    <img src={CarbonpaperLogoOnly} alt="Carbon Paper Logo" className="h-6" />
                  </a>
                </>
              )}
            </div>
            {currentUser && (
              <div className="flex-none">
                <div className="dropdown dropdown-end">
                  <div tabIndex={0} role="button" className="btn btn-ghost pr-0 border-0 hover:bg-brand-fadegray/5">
                    {profilePicUrl ? (
                      <div className='avatar btn-circle btn border-0 bg-transparent'>
                        <div className="w-8 rounded-full">
                          <img alt={username} src={profilePicUrl} />
                        </div>
                      </div>
                    ) : (
                      <div className="avatar placeholder">
                        <div className="bg-neutral text-neutral-content w-8 rounded-full">
                          <span className="text-xl">{username[0]}</span>
                        </div>
                      </div>
                    )}
                    <div className='mx-2 flex-col h-7 hidden sm:flex'>
                      <div className='flex flex-1 capitalize'>{username}</div>
                      <div className='flex flex-1 justify-end pt-1 lowercase font-light'>
                        {currentUser.emailId} <ChevronDown size={16} />
                      </div>
                    </div>
                  </div>
                  <ul tabIndex={0} className="menu menu-sm dropdown-content bg-brand-darkgray rounded-box z-[1] mt-3 w-52 p-2 shadow">
                    <li onClick={() => { logoutMutation.mutate() }}><a>Logout</a></li>
                  </ul>
                </div>
              </div>
            ) || (
              <AuthPanel
                autoOpen={autoOpenAuthPanel}
                content="Login"
                className={`text-brand-lightgray py-2 rounded-md w-28 justify-center
                   border-brand-lightgray border-2 shadow-fadegray bg-brand-darkgray transition-all`}
                callback={() => {}}
                errorCallback={() => {}}
              />
            )}
          </div>
          <main className='flex flex-col z-30 overflow-auto h-full'>
            <Drawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
            <Outlet context={{ isDrawerOpen, setIsDrawerOpen }} />
          </main>
          <div className='z-50'>
            <Toast ref={toastRef} />
            <AppDownloadBanner toggleSwitch={showDownloadAppbar} />
          </div>
        </div>
      </ToastContext.Provider>
    </GlobalContext.Provider>
  );
};

export default Layout;