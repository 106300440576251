import moment from 'moment';
import { getFormFieldValue } from './form';

export const createEventFormData = (
  form: HTMLFormElement, 
  selectedFile: File | null
): FormData | { error: string } => {
  const startDate = getFormFieldValue(form, 'startIsoTime');
  const endDate = getFormFieldValue(form, 'endIsoTime');

  // Create UTC midnight timestamps
  const startDateTime = moment.utc(startDate).startOf('day').format();
  const endDateTime = moment.utc(endDate).startOf('day').format();
  // Validate that start date is today or later
  if (!moment(startDateTime).isSameOrAfter(moment().startOf('day'))) {
    return { error: 'Start date must be today or later' };
  }

  // Validate that end date is not before start date
  if (!moment(endDateTime).isSameOrAfter(moment(startDateTime))) {
    return { error: 'End date must be after or same as start date' };
  }

  const formData = new FormData();
  formData.append('name', getFormFieldValue(form, "name"));
  formData.append('startIsoTime', startDateTime);
  formData.append('endIsoTime', endDateTime);
  formData.append('privacy', form.privacy.value);
  
  if (selectedFile) {
    formData.append('file', selectedFile);
  }
  
  return formData;
};