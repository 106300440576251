import React, { useContext, useEffect, useRef } from 'react';
import { Calendar, Camera, ChevronDown, ChevronUp, Play } from 'lucide-react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import QuerySrvc from '../../services/queries/QuerySrvc';
import { ToastContext } from '../Contexts';
import DayCard from './DayCard';
import { EventViewMode } from '../events/EventView';

interface DayListProps {
  days: any[];
  selectedDayId: string | null;
  onElementClick: (day: any) => void;
  mode: EventViewMode

}

export function DayList({ days, selectedDayId, onElementClick, mode }: DayListProps) {
  const { eventId } = useParams<{ eventId: string }>();
  const queryClient = useQueryClient();


  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const currentIndex = days.findIndex(day => day.id === selectedDayId);



  const scrollToDay = (direction?: 'up' | 'down', index?: number | null) => {
    const newIndex = (index === null || index === undefined) ? (direction === 'up'
      ? Math.max(0, currentIndex - 1)
      : Math.min(days.length - 1, currentIndex + 1)) : index;

    if (newIndex !== currentIndex && days[newIndex]) {
      onElementClick(days[newIndex]);
      const container = scrollContainerRef.current;
      const dayElements = container?.getElementsByClassName('day-card');
      if (container && dayElements && dayElements[newIndex]) {
        const dayElement = dayElements[newIndex] as HTMLElement;
        dayElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
      }
    }
  };

  return (
    <div className="flex flex-1 flex-col w-full">

      <button
        onClick={() => scrollToDay('up')}
        disabled={currentIndex <= 0}
        className={`sticky hidden sm:flex top-0 z-10 w-full py-2 backdrop-blur-sm
          ${currentIndex <= 0 ? 'invisible' : 'text-brand-lightgray cursor-pointer'}`} >
        <ChevronUp className="mx-auto w-6 h-6" />
      </button>
      <div ref={scrollContainerRef} className="flex flex-1 flex-col space-y-10 overflow-y-auto overflow-x-hidden custom-scroll">
        {days.map((day, index) => (
          <DayCard key={index} index={index} day={day} scrollToDay={scrollToDay}
            selectedDayId={selectedDayId} onElementClick={onElementClick} mode={mode}
          />
        ))}
      </div>
      <button
        onClick={() => scrollToDay('down')}
        disabled={currentIndex >= days.length - 1}
        className={`sticky hidden sm:flex bottom-0 z-10 w-full py-2 backdrop-blur-sm 
          ${currentIndex >= days.length - 1 ? 'invisible' : 'text-brand-lightgray cursor-pointer'}`} >
        <ChevronDown className="mx-auto w-6 h-6" />
      </button>
    </div>
  );
}