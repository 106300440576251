import { createContext } from "react";

export const ToastContext = createContext<{ showSuccessToast: (message: string) => void, showErrorToast: (message: string) => void }>({
  showSuccessToast: () => { },
  showErrorToast: () => { }
});

export const GlobalContext = createContext<any>({});

export const CurrentUserContext = createContext<any>(null);
