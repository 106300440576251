export const getErrorStringFromObject = (error: any) => {
  return (error?.response?.data?.error) || error?.error?.data?.error || error?.error?.data || error?.error?.error || error?.error?.toString();
}

export const getProfilePic = (pic: any) => {
  return typeof pic === 'string' && pic || (pic?.thumbnail || pic?.original || pic?.url) || "";
}

export const getUserNameOrEmail = (user: any) => {

  return user ? (`${user?.firstName} ${user?.lastName ? user?.lastName : ''}` || user?.email) : undefined;
}

export const delay = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}