import axios from 'axios';
import HttpSrvc from '../../../services/HttpSrvc';

const API_URL = '/oapi/itinerary';

export const FetchItinerary = async (itineraryId: string) => {
  try {
    return await HttpSrvc.get(`${API_URL}/${itineraryId}`);
  } catch (error) {
    console.error('Error fetching itinerary:', error);
    throw error;
  }
};