import React, { useEffect, useState } from 'react';
import { Mail, Lock, ArrowLeft, Check } from 'lucide-react';
import QuerySrvc from '../../../services/queries/QuerySrvc';
import { getErrorStringFromObject } from '../../../services/utils';

type ForgotPasswordStep = 'email' | 'otp' | 'reset' | 'success';

interface ForgotPasswordProps {
  onBack: () => void;
}

export default function ForgotPassword({ onBack }: ForgotPasswordProps) {
  const [step, setStep] = useState<ForgotPasswordStep>('email');
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const sendOtpMutation = QuerySrvc.MUTATIONS.OPEN.SEND_OTP();
  const verifyOtpMutation = QuerySrvc.MUTATIONS.OPEN.VERIFY_OTP();
  const resetPasswordMutation = QuerySrvc.MUTATIONS.OPEN.RESET_PASSWORD_BY_OTP();

  const [lastSuccessfullOtpId, setLastSuccessfullOtpId] = useState<string>('');

  const handleEmailSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      sendOtpMutation.mutate({ emailOrPhone: email, otpType: 'PASSWORD_RESET' });
    } catch (err) {
      setError('Failed to send OTP. Please try again.');
    }
  };

  const handleOTPVerify = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      verifyOtpMutation.mutate({ id: lastSuccessfullOtpId, otp });
    } catch (err) {
      setError('Invalid OTP. Please try again.');
    }
  };

  const handlePasswordReset = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      // TODO: Implement password reset API call

      resetPasswordMutation.mutate({
        "emailId": email,
        "password": newPassword,
        "otpId": lastSuccessfullOtpId
      });
    } catch (err) {
      setError('Failed to reset password. Please try again.');
    }
  };

  useEffect(() => {
    if (sendOtpMutation.status === 'success') {
      setLastSuccessfullOtpId(sendOtpMutation.data.id);
      setStep('otp');
      setError(null);
    }
    else if (sendOtpMutation.status === 'error') {
      setError('Failed to send OTP. Please try again.');
    }
  }, [sendOtpMutation.status]);

  useEffect(() => {
    if (verifyOtpMutation.status === 'success') {
      setStep('reset');
      setError(null);
    }
    else if (verifyOtpMutation.status === 'error') {
      setError('Invalid OTP. Please try again.');
    }
  }, [verifyOtpMutation.status]);

  useEffect(() => {
    if (resetPasswordMutation.status === 'success') {
      setStep('success');
      setError(null);
    }
    else if (resetPasswordMutation.status === 'error') {
      setError(getErrorStringFromObject(resetPasswordMutation.error) || 'Failed to reset password. Please try again.');
    }
  }
    , [resetPasswordMutation.status]);

  const renderEmailStep = () => (
    <form onSubmit={handleEmailSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-brand-lightgray mb-1">
          Email address
        </label>
        <div className="relative">
          <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-brand-lightgray focus:border-brand-lightgray"
            placeholder="you@example.com"
            required
          />
        </div>
      </div>
      <button
        type="submit"
        className={`w-full bg-brand-darkgray boder-brand-fadegray border-2 ${sendOtpMutation.status === 'pending' ? 'text-brand-fadegray' : 'text-white'} px-4 py-2
              rounded-md hover:bg-brand-lightgray/10 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors`} >
        Send OTP
      </button>
    </form>
  );

  const renderOTPStep = () => (
    <form onSubmit={handleOTPVerify} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-brand-lightgray mb-1">
          Enter OTP sent to {email}
        </label>
        <input
          type="text"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-brand-lightgray focus:border-brand-lightgray"
          placeholder="Enter 6-digit OTP"
          maxLength={6}
          required
        />
      </div>
      <button
        type="submit"
        className={`w-full bg-brand-darkgray boder-brand-fadegray border-2 ${verifyOtpMutation.status === 'pending' ? 'text-brand-fadegray' : 'text-white'} px-4 py-2
              rounded-md hover:bg-brand-lightgray/10 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors`}  >
        Verify OTP
      </button>
    </form>
  );

  const renderResetStep = () => (
    <form onSubmit={handlePasswordReset} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-brand-lightgray mb-1">
          New Password
        </label>
        <div className="relative">
          <Lock className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            placeholder="Enter new password"
            required
          />
        </div>
      </div>
      <div>
        <label className="block text-sm font-medium text-brand-lightgray mb-1">
          Confirm Password
        </label>
        <div className="relative">
          <Lock className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            placeholder="Confirm new password"
            required
          />
        </div>
      </div>
      <button
        type="submit"
        className={`w-full bg-brand-darkgray boder-brand-fadegray border-2 ${verifyOtpMutation.status === 'pending' ? 'text-brand-fadegray' : 'text-white'} px-4 py-2
              rounded-md hover:bg-brand-lightgray/10 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors`}  >
        Reset Password
      </button>
    </form>
  );

  const renderSuccessStep = () => (
    <div className="text-center py-8">
      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mb-4">
        <Check className="h-6 w-6 text-green-600" />
      </div>
      <h3 className="text-lg font-medium text-brand-lightgray mb-2">
        Password Reset Successfully!
      </h3>
      <p className="text-sm text-brand-lightgray  mb-4">
        You can now sign in with your new password.
      </p>
      <button
        onClick={onBack}
        className="w-full bg-brand-darkgray boder-brand-fadegray border-2 px-4 py-2
              rounded-md hover:bg-brand-lightgray/10 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors"
      >
        Back to Sign In
      </button>
    </div>
  );

  return (
    <div className="space-y-6">
      <div className="flex items-center">
        <button
          onClick={onBack}
          className="text-gray-400 hover:text-gray-600 transition-colors"
        >
          <ArrowLeft className="w-5 h-5" />
        </button>
        <h2 className="text-xl text-brand-lightgray font-bold m-4">Reset Password</h2>
      </div>

      {step === 'email' && renderEmailStep()}
      {step === 'otp' && renderOTPStep()}
      {step === 'reset' && renderResetStep()}
      {step === 'success' && renderSuccessStep()}
      {
        error && <p className="mt-1 text-sm text-center text-red-600">{error}</p>
      }
    </div>
  );
}