import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import ApiService from '../../utils/api';
import { formatDate } from '../../utils/date';
import { getUserNameOrEmail, getProfilePic } from '../../services/utils';
import EventFeeds from './EventFeeds';
import EventTabs from './EventTabs';
import ItineraryViewer from '../itinerary/ItineraryViewer';

interface Event {
  id: string;
  name: string;
  description: string;
  startTime: string;
  endTime: string;
  image: string;
  owner: {
    firstName: string;
    lastName: string;
    emailId: string;
    profilePic: string;
  };
}

type TabType = 'itinerary' | 'social' | 'attendees';

export enum EventViewMode {
  VIEW,
  MANAGE
}

const EventView = ({ mode = EventViewMode.VIEW }: { mode?: EventViewMode }) => {
  const { eventId } = useParams<{ eventId: string }>();
  const [activeTab, setActiveTab] = useState<TabType>('itinerary');

  const { data: event, isLoading, error } = useQuery<Event, any>({
    queryKey: ['event', eventId],
    queryFn: () => ApiService.get(`/api/events/${eventId}`),
    enabled: !!eventId
  });

  if (isLoading) return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-brand-lightgray"></div>
    </div>
  );

  if (error) {
    let message = "Error loading event";
    if (error.response?.status === 404) {
      message = "Event not found";
    }
    return (
      <div className="flex justify-center items-center min-h-screen text-brand-lightgray">
        {message}
      </div>
    );
  }

  if (!event) return null;

  const renderTabContent = () => {
    switch (activeTab) {
      case 'itinerary':
        return eventId && <ItineraryViewer key={eventId} itineraryId={eventId} mode={mode} />;
      case 'social':
        return eventId && <EventFeeds eventId={eventId} />;
      case 'attendees':
        return (
          <div className="text-center py-8 text-brand-lightgray flex flex-1 justify-center items-center">
            Attendees feature coming soon
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-1 flex-col bg-brand-darkgray text-brand-lightgray overflow-y-auto">

      <div className={`sticky flex ${mode === EventViewMode.MANAGE ? '' : 'top-0'} z-40 bg-brand-darkgray`}>
        <div className="px-4">
          <EventTabs activeTab={activeTab} onTabChange={setActiveTab} />
        </div>
      </div>

      {/* Tab Content */}
      <div className="flex flex-1 p-0 overflow-y-auto">
        {renderTabContent()}
      </div>
    </div>
  );
};

export default EventView;