import React, { useEffect, useRef, useState } from "react";
import httpSrvc from "../services/HttpSrvc";
import CONSTANTS from "../config/constants";

interface GoogleSignInProps {
  /* prompt_parent_id attribute sets the DOM ID of the container element. 
  If it's not set, the One Tap prompt is displayed in the top-right corner of the window. */
  prompt_parent_id?: string;
  successLoginCallback: (...args: any[]) => void;
  errorCallback: (...args: any[]) => void;
}

export default (props: GoogleSignInProps) => {
  const refEl = useRef<any>(0);
  const [isInitialized, setIsInitialized] = useState(false);
  const gsiSrc = "https://accounts.google.com/gsi/client";

  const loadScript = (src: string) => {
    return new Promise((resolve, reject) => {
      if (document.querySelector(`script[src="${src}"]`)) {
        return resolve(src);
      }
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve(src);
      script.onerror = (err) => reject(err);
      document.body.appendChild(script);
    });
  };

  const handleCredentialResponse = async (credentials: any) => {
    try {
      const response = await httpSrvc.post("/auth/gauth", { credential: credentials.credential });
      return props.successLoginCallback(response);
    } catch (error) {
      console.log(error);
      return props.errorCallback(error);
    }
  };

  const initializeGSI = async () => {
    if (isInitialized) return;

    try {
      await loadScript(gsiSrc);
      const google = window.google;

      if (google) {
        google.accounts.id.initialize({
          client_id: CONSTANTS.ENV_CONSTANTS.G_CLIENT_ID,
          callback: handleCredentialResponse,
          ux_mode: "popup",
          prompt_parent_id: props.prompt_parent_id,
          itp_support: false,
          cancel_on_tap_outside: false
        });

        const parent = refEl.current;
        if (parent) {
          google.accounts.id.renderButton(parent, {
            type: "standard",
            theme: "outline",
            shape: "pill",
            text: "signin_with",
            logo_alignment: "left"
          });
        }
        setIsInitialized(true);
      }
    } catch (err) {
      console.error(err);
      props.errorCallback(err);
    }
  };

  useEffect(() => {
    initializeGSI();

    return () => {
      const scriptTag = document.querySelector(`script[src="${gsiSrc}"]`);
      if (scriptTag) document.body.removeChild(scriptTag);
      setIsInitialized(false);
    };
  }, []);

  return (
    <div id="googleSignInButtonContainer" className="color-scheme-none flex justify-center" ref={refEl}></div>
  );
};