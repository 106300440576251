import moment from 'moment';

export const combineDateTime = (date: string, time: string): string => {
  // Create a moment object from the date and time
  const dateTime = moment.utc(`${date} ${time}`, 'YYYY-MM-DD HH:mm');
  return dateTime.toISOString();
};

export const validateDateTime = (dateTime: string): boolean => {
  return moment(dateTime).isValid();
};

export const isEndTimeAfterStartTime = (startDateTime: string, endDateTime: string): boolean => {
  const start = moment(startDateTime);
  const end = moment(endDateTime);
  return end.isAfter(start);
};