import React, { useContext, useEffect, useState } from 'react';
import { X } from 'lucide-react';
import ActivityFormFields from './form/ActivityFormFields';
import { createActivityFormData } from '../../utils/form';
import { useQueryClient } from '@tanstack/react-query';
import { ToastContext } from '../Contexts';
import QuerySrvc from '../../services/queries/QuerySrvc';
import { getErrorStringFromObject } from '../../services/utils';

interface AddActivityFormProps {
  onClose: () => void;
  submitSuccessCallback?: (response: any) => void;
  submitErrorCallback?: (error: any) => void;
  selectedDay: {
    date: string;
    id: string;
    eventId: string;
  };
}

const AddActivityForm: React.FC<AddActivityFormProps> = ({ onClose, selectedDay, submitSuccessCallback, submitErrorCallback }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const createActivityMutation = QuerySrvc.MUTATIONS.ACTIVITY.CREATE_ACTIVITY(queryClient);

  useEffect(() => {
    if (createActivityMutation.status === 'success') {
      submitSuccessCallback && submitSuccessCallback(createActivityMutation.data);
    } else if (createActivityMutation.status === 'error') {
      setError(getErrorStringFromObject(createActivityMutation.error));
      submitErrorCallback && submitErrorCallback(createActivityMutation.error);
    }
  }, [createActivityMutation.status]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);

    const result = createActivityFormData(e.currentTarget as HTMLFormElement, selectedFile);

    if ('error' in result) {
      setError(result.error);
      return;
    }

    handleFormSubmit(result);
  };

  const handleFormSubmit = async (formData: FormData) => {
    try {
      createActivityMutation.mutate({
        eventId: selectedDay.eventId,
        dayId: selectedDay.id,
        formData
      });
    } catch (error: any) {
      console.error('Failed to create activity:', error);
    }
  };

  return (
    <div className="flex flex-1 flex-col inset-0 flex items-center justify-center z-50 overflow-y-auto">
      <div className="relative flex flex-col border border-brand-fadegray rounded-lg p-4 w-full max-w-lg ">
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-brand-lightgray hover:text-brand-lightgray/80"
          disabled={createActivityMutation.isPending} >
          <X className="w-5 h-5" />
        </button>

        <h2 className="text-xl font-semibold text-brand-lightgray mb-4">Add New Activity</h2>

        {error && (
          <div className="mb-4 p-3 bg-red-500/10 border border-red-500 rounded text-red-500">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <ActivityFormFields
            onFileChange={setSelectedFile}
            selectedDay={selectedDay}
          />

          <div className="flex justify-end space-x-4 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-brand-fadegray text-brand-lightgray rounded-lg enabled:hover:bg-brand-fadegray/20"
              disabled={createActivityMutation.isPending}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`px-4 py-2 bg-brand-lightgray text-brand-darkgray rounded-lg w-40
                enabled:hover:bg-brand-lightgray/90 disabled:opacity-50 disabled:text-brand-fadegray`}
              disabled={createActivityMutation.isPending}
            >
              {
                createActivityMutation.isPending &&
                <span className="loading loading-bars loading-xs"></span> ||
                <span>
                  Add Activity
                </span>
              }
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddActivityForm;