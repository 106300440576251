export default {
  STORAGE_PREFIX: "cp.v1",  
}

export const STORAGE_SRVC_KEYS = {
  CURRENT_USER: 'CURRENT_USER',
  AUTH_TOKEN: 'AUTH_TOKEN',
  USER_PROFILE: 'USER_PROFILE',
  USER_CHAT_LIST: 'USER_CHAT_LIST',
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  PLATFORM: 'PLATFORM',
  APP_VERSION: 'APP_VERSION',
  ENCRYPTION_KEY: 'ENCRYPTION_KEY',
  TOKEN: 'TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  CHAT_KEY: 'CHAT_KEY',
  STATE_PERSISTANCE_KEY: 'STATE_PERSISTANCE_KEY',
  DEVICE_TOKEN_KEY: 'DEVICE_TOKEN_KEY',
}