import AppStrings from "../config/constants/AppStrings";

class StorageSrvc {

  setItem(key: string, data: string | {}, expiryInMilliseconds: number = 0) {
    key = AppStrings.STORAGE_PREFIX + key;
    const payload: any = {
      data
    };

    if (expiryInMilliseconds) {
      payload.expiry = Date.now() + expiryInMilliseconds;
    }

    localStorage.setItem(key, JSON.stringify(payload));
  }

  getItem(key: string) {
    key = AppStrings.STORAGE_PREFIX + key;
    const item = localStorage.getItem(key);
    if (item) {
      try {
        const payload = JSON.parse(item);
        if (payload.expiry && payload.expiry < Date.now()) {
          localStorage.removeItem(key);
          return null;
        }
        return payload.data;
      } catch (err) {
        return item;
      }
    }
    return null;
  }

  removeItem(key: string) {
    key = AppStrings.STORAGE_PREFIX + key;
    return localStorage.removeItem(key);
  }

  getItemsList() {
    return Object.keys(localStorage);
  }

  clear() {
    const keys = this.getItemsList();
    keys.map(async (key) => {
      localStorage.removeItem(key);
    });
  }
}

export default new StorageSrvc();