import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { AlertCircle, CheckCircle2, X } from 'lucide-react';

export type ToastType = 'success' | 'error';

export interface ToastHandle {
  show: (message: string, type: ToastType) => void;
}

const Toast = forwardRef<ToastHandle>((_, ref) => {
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState<ToastType>('success');

  useImperativeHandle(ref, () => ({
    show: (newMessage: string, newType: ToastType) => {
      setMessage(newMessage);
      setType(newType);
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
      }, 3000);
    },
  }));

  if (!visible) return null;
  return (
    <div className="toast toast-top toast-end z-50">
      <div
        className={`alert ${type === 'success' ? 'alert-success' : 'alert-error'
          } flex items-center gap-2`}
      >
        {type === 'success' ? (
          null
        ) : (
          <AlertCircle className="h-5 w-5" />
        )}
        <span className='max-w-xs	 whitespace-normal'>{message}</span>
        <button
          onClick={() => setVisible(false)}
          className=""
        >
          <X className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
});

Toast.displayName = 'Toast';

export default Toast;