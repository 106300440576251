import React from 'react';

interface FeatureCardProps {
  title: string;
  description: string[];
  image: string;
  isLast: boolean;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ title, description, image, isLast }) => {
  return (
    <>
      <div className="card w-full lg:w-96 shadow-xl grayscale hover:grayscale-0">
        <h2 className="card-title my-8">{title}</h2>
        <div className="flex h-48">
          <img 
            className="object-cover w-full h-48 rounded-md"
            src={image}
            alt={title} 
          />
        </div>
        <div className="card-body p-0 flex flex-1">
          <div className="mt-8">
            {description.map((text, index) => (
              <div key={`${title}-desc-${index}`} className="pb-4 text-md">
                {text}<br />
              </div>
            ))}
          </div>
        </div>
      </div>
      {!isLast && <div className="divider lg:divider-horizontal mt-16" />}
    </>
  );
};

export default FeatureCard;