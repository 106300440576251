import React from 'react';
import { Calendar, Users, MessageSquare } from 'lucide-react';

type TabType = 'itinerary' | 'social' | 'attendees';

interface EventTabsProps {
  activeTab: TabType;
  onTabChange: (tab: TabType) => void;
}

const EventTabs: React.FC<EventTabsProps> = ({ activeTab, onTabChange }) => {
  const tabs = [
    { id: 'itinerary' as TabType, label: 'Itinerary', icon: Calendar },
    { id: 'social' as TabType, label: 'Social', icon: MessageSquare },
    { id: 'attendees' as TabType, label: 'Attendees', icon: Users }
  ];

  return (
    <div className="flex space-x-4 bg-brand-darkgray z-40">
      {tabs.map(tab => {
        const Icon = tab.icon;
        return (
          <button
            key={tab.id}
            onClick={() => onTabChange(tab.id)}
            className={`flex items-center px-4 py-2 transition-colors ${activeTab === tab.id
              ? 'text-brand-lightgray border-b'
              : 'text-brand-lightgraybackup hover:text-brand-lightgray hover:bg-brand-lightgray/5'
              }`}
          >
            <Icon className="w-4 h-4 mr-2" />
            <span className="ml-2 bg-brand-fadegray/20 px-2 py-0.5 rounded-full text-sm">
              {tab.label}
            </span>
          </button>
        );
      })}
    </div>
  );
};

export default EventTabs;