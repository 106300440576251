import React from 'react';

interface DateInputProps {
  label: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
}

const DateInput: React.FC<DateInputProps> = ({
  label,
  name,
  value,
  onChange,
  required = false
}) => {
  return (
    <div>
      <label className="block text-sm font-medium text-brand-lightgray mb-1">
        {label} {required && '*'}
      </label>
      <input
        type="date"
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        className="w-full p-2 bg-brand-fadegray/20 border border-brand-fadegray rounded-lg text-brand-lightgray"
      />
    </div>
  );
}

export default DateInput;