import React from 'react';
import { useQuery } from '@tanstack/react-query';
import ApiService from '../../utils/api';
import FeedCard from './FeedCard';
import { Feed, PaginatedResponse } from './types';

interface EventFeedsProps {
  eventId: string;
}

const EventFeeds = ({ eventId }: EventFeedsProps) => {
  const { data: feedsResponse, isLoading, error } = useQuery<PaginatedResponse<Feed>>({
    queryKey: ['feeds', eventId],
    queryFn: () => ApiService.get(`/api/feeds/${eventId}`),
    enabled: !!eventId
  });

  if (isLoading) return (
    <div className="flex justify-center items-center py-8">
      <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-brand-lightgray"></div>
    </div>
  );

  if (error) return (
    <div className="text-center py-8 text-brand-lightgray">
      Error loading feeds
    </div>
  );

  if (!feedsResponse?.feeds?.length) return (
    <div className="text-center py-8 text-brand-lightgray flex flex-1 justify-center items-center">
      No feeds available yet
    </div>
  );

  return (
    <div className="container mx-auto px-4 md:max-w-2xl">
      {/* <h2 className="text-2xl font-semibold text-brand-lightgray mb-8 text-center">Event Feeds</h2> */}
      <div className="space-y-6">
        {feedsResponse.feeds.map((feed) => (
          <FeedCard key={feed.id} feed={feed} />
        ))}
      </div>
    </div>
  );
};

export default EventFeeds;