import moment from 'moment';

export const getDefaultStartTime = (selectedDate: string): string => {
  const now = moment();
  const dateTime = moment(selectedDate);
  
  // Set time to current time
  dateTime.hours(now.hours());
  dateTime.minutes(now.minutes());
  
  // If time is past 23:00, set to 23:00
  if (dateTime.hours() >= 23) {
    dateTime.hours(23);
    dateTime.minutes(0);
  }
  
  return dateTime.format('HH:mm');
};

export const getDefaultEndTime = (selectedDate: string): string => {
  const startDateTime = moment(selectedDate);
  const startTime = getDefaultStartTime(selectedDate);
  const [hours, minutes] = startTime.split(':');
  
  startDateTime.hours(parseInt(hours));
  startDateTime.minutes(parseInt(minutes));
  
  // Add one hour
  const endDateTime = startDateTime.clone().add(1, 'hour');
  
  // If end time would be past 23:00, set to 23:00
  if (endDateTime.hours() >= 23) {
    endDateTime.hours(23);
    endDateTime.minutes(0);
  }
  
  return endDateTime.format('HH:mm');
};