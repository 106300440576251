import React, { useState, useRef } from 'react';
import moment from 'moment';
import { Camera, Globe, Users } from 'lucide-react';
import DateInput from './DateInput';

interface EventFormFieldsProps {
  onFileChange: (file: File | null) => void;
}

const EventFormFields: React.FC<EventFormFieldsProps> = ({ onFileChange }) => {
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [selectedPrivacy, setSelectedPrivacy] = useState<'PUBLIC' | 'PRIVATE'>('PUBLIC');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileSrc, setFileSrc] = useState<string | null>(null);

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);

    // If end date is before start date, update end date to start date
    if (moment(endDate).isBefore(moment(newStartDate))) {
      setEndDate(newStartDate);
    }
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = e.target.value;
    if (moment(newEndDate).isSameOrAfter(moment(startDate))) {
      setEndDate(newEndDate);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  function handleFile(file: File) {

    if (!file.type.startsWith("image/")) {
      return;
    }

    // const img = document.createElement("img");
    // img.classList.add("obj");
    // img.file = file;
    // preview.appendChild(img); // Assuming that "preview" is the div output where the content will be displayed.

    const reader = new FileReader();
    reader.onload = (e) => {
      const src = e?.target?.result;
      console.log(src)
      src && setFileSrc(src as string);
    };

    reader.readAsDataURL(file);

  }

  console.log(fileInputRef, fileSrc)
  return (
    <>
      <div className="form-control w-full">
        <label className="label">
          <span className="label-text text-brand-lightgray">Event Name *</span>
        </label>
        <input
          type="text"
          name="name"
          required
          placeholder="Enter event name"
          className="input input-bordered w-full bg-brand-fadegray/20 border-brand-fadegray text-brand-lightgray"
        />
      </div>

      <div className="grid grid-cols-2 gap-4 my-4">
        <DateInput
          label="Start Date"
          name="startIsoTime"
          value={startDate}
          onChange={handleStartDateChange}
          required
        />
        <DateInput
          label="End Date"
          name="endIsoTime"
          value={endDate}
          onChange={handleEndDateChange}
          required
        />
      </div>

      {/* Rest of the component remains the same */}
      <div className="form-control w-full my-4">
        <label className="label">
          <span className="label-text text-brand-lightgray">Privacy *</span>
        </label>
        <div className="flex gap-4">
          <label className={`flex-1 flex items-center gap-3 p-0 px-4 rounded-lg cursor-pointer border transition-colors ${selectedPrivacy === 'PUBLIC'
            ? 'border-brand-lightgray bg-brand-lightgray/10'
            : 'border-brand-fadegray bg-brand-fadegray/20'
            }`}>
            <input
              type="radio"
              name="privacy"
              value="PUBLIC"
              checked={selectedPrivacy === 'PUBLIC'}
              onChange={(e) => setSelectedPrivacy(e.target.value as 'PUBLIC' | 'PRIVATE')}
              className="hidden"
            />
            <Globe className="w-5 h-5 text-brand-lightgray" />
            <div className="flex flex-col">
              <span className="text-brand-lightgray font-medium">Public Event</span>
              <span className="text-sm text-brand-lightgraybackup">Visible to everyone</span>
            </div>
          </label>

          <label className={`flex-1 flex items-center gap-3 p-3 rounded-lg cursor-pointer border transition-colors ${selectedPrivacy === 'PRIVATE'
            ? 'border-brand-lightgray bg-brand-lightgray/10'
            : 'border-brand-fadegray bg-brand-fadegray/20'
            }`}>
            <input
              type="radio"
              name="privacy"
              value="PRIVATE"
              checked={selectedPrivacy === 'PRIVATE'}
              onChange={(e) => setSelectedPrivacy(e.target.value as 'PUBLIC' | 'PRIVATE')}
              className="hidden"
            />
            <Users className="w-5 h-5 text-brand-lightgray" />
            <div className="flex flex-col">
              <span className="text-brand-lightgray font-medium">Private Event</span>
              <span className="text-sm text-brand-lightgraybackup">Only visible to invited guests</span>
            </div>
          </label>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-brand-lightgray mb-1">
          Cover Image
          <span className="text-xs text-brand-lightgray ml-2 font-thin italic text-brand-fadegray">
            {
              fileInputRef?.current?.files?.[0]?.name
            }
          </span>
        </label>
        <input
          type="file"
          ref={fileInputRef}
          accept="image/*"
          onChange={(e) => {
            e.target.files?.[0] && handleFile(e.target.files?.[0]);
            onFileChange(e.target.files?.[0] || null);
          }}
          className="hidden"
        />
        <div
          onClick={triggerFileInput}
          className={`relative w-full h-24 bg-brand-fadegray/20 border-2 border-dashed border-brand-fadegray rounded-lg flex flex-col 
            items-center justify-center cursor-pointer hover:bg-brand-fadegray/30 transition-colors`}
        >
          {fileSrc &&
            <img src={fileSrc} alt="Cover Image" className="absolute top-0 aspect-video mx-auto h-full object-cover rounded-lg" />
          }
          <Camera className="w-6 h-6 text-brand-lightgray mb-1" />
          <p className="text-sm text-brand-lightgray">{
            !fileInputRef?.current?.files?.[0]?.name && `Click to upload cover image`
          }</p>
        </div>
      </div>
    </>
  );
};

export default EventFormFields;