import React from "react";
import CarbonpaperLogo from "./../images/carbonpaper-logo.svg";

const TermsAndConditions = () => {
  return (
    <div className="bg-brand-darkgray" style={styles.compliancePage}>
      <div style={styles.headerContainer}>
        <a style={styles.headerContainer} href="/" target='_blank'>
          <img className="w-20" src={CarbonpaperLogo} alt="CP" />
          <p style={styles.headerText}>DIGIALT CARBONPAPER APPS PRIVATE LIMITED</p>
        </a>
      </div>
      <div style={styles.complianceContent}>
        <div style={styles.contentContainer}>
          <p style={styles.contentHead}>Contact Us</p>
          <div style={styles.contentSeparator}></div>
          <p style={styles.updatedDate}>Last updated on Mar 30th 2024</p>
          <p style={styles.contentText}>
            You may contact us using the information below:
          </p>
          <p style={styles.contentText}>
            <strong>Merchant Legal entity name: </strong>DIGIALT CARBONPAPER APPS PRIVATE LIMITED
          </p>
          <p style={styles.contentText}>
            <strong>Registered Address: </strong>Unit 101, Oxford Towers, 139, HAL Old Airport Rd, Hulsur Bazaar, Bangalore North Bengaluru KARNATAKA 560008
          </p>
          <p style={styles.contentText}>
            <strong>Telephone No: </strong>+91 9686648597
          </p>
          <p style={styles.contentText}>
            <strong>E-Mail ID: </strong>support@carbonpaper.app
          </p>        
        </div>
      </div>
    </div>
  );
}

const styles = {
  body: {
    margin: 0,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '24px',
  },
  headerText: {
    fontSize: '18px',
    lineHeight: '24px',
    color: '#fff',
    margin: '0 0 0 18px',
  },
  compliancePage: {
    // background: '#0a1d38',
    fontFamily: 'Lato, sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
  },
  contentContainer: {
    margin: '0 auto',
    maxWidth: '1000px',
  },
  complianceContent: {
    background: '#ffffff',
    borderRadius: '32px 32px 0 0',
    padding: '32px 24px',
  },
  contentHead: {
    fontSize: '24px',
    lineHeight: '30px',
    color: '#213554',
    margin: 0,
    fontWeight: '700',
  },
  contentSeparator: {
    width: '28px',
    height: '5px',
    backgroundColor: '#213554',
    marginTop: '16px',
  },
  updatedDate: {
    margin: '16px 0 0',
    color: '#213554ab',
    fontWeight: '700',
  },
  contentText: {
    color: '#515978',
    margin: '16px 0 0',
    fontSize: '14px',
    lineHeight: '20px',
  },
  merchantLogo: {
    width: '74.6px',
    height: '64px',
    background: '#fefefe',
    boxShadow: '0px 0px 8px #00000040',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '50px',
    background: '#0a1d38',
    boxShadow: '11.0003px 22.0007px 53.9016px rgba(0, 0, 0, 0.1), 22.0007px 11.0003px 53.9854px -2.03719px rgba(255, 255, 255, 0.1)',
    borderRadius: '50%',
  },
  logoText: {
    color: '#fff',
    fontWeight: '700',
    fontSize: '32px',
  },
  listItem: {
    display: 'list-item',
    paddingLeft: '5px',
  },
  unorderList: {
    margin: 0,
  },
  listText: {
    marginTop: '8px',
  },
};

export default TermsAndConditions;