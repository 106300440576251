import { QueryClient, useMutation } from '@tanstack/react-query';
import HttpSrvc from '../HttpSrvc';

export const MUTATIONS = {
  UPDATE_PROGRAM: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, dayId, payload }: { eventId: string; dayId: string; payload: any }) => {
      return HttpSrvc.put(`/api/events/${eventId}/programs/${dayId}`, payload);
    },
    onSuccess: (data: any) => {
      queryClient?.invalidateQueries({ queryKey: ["itineraryData", data.eventId] });
    }
  }),

  UPLOAD_PROGRAM_IMAGE: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, dayId, file }: { eventId: string; dayId: string; file: File }) => {
      const formData = new FormData();
      formData.append('file', file);
      return HttpSrvc.postFormData(`/api/events/${eventId}/programs/${dayId}/image`, formData);
    },
    onSuccess: (data: any) => {      
      queryClient?.invalidateQueries({ queryKey: ["itineraryData", data.eventId], });
    }
  })
}

export const PROGRAM_CONSTANTS = {
  QUERY_KEYS: {
    PROGRAM: 'PROGRAM'
  }
}