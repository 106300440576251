import React, { useState } from 'react';
import { formatDate } from '../../../utils/date';
import TimeInput from './TimeInput';
import { getDefaultStartTime, getDefaultEndTime } from '../../../utils/time';
import moment from 'moment';

interface ActivityFormFieldsProps {
  onFileChange: (file: File | null) => void;
  selectedDay: {
    date: string;
  };
}

const ActivityFormFields: React.FC<ActivityFormFieldsProps> = ({ onFileChange, selectedDay }) => {
  const selectedDate = formatDate(selectedDay.date, 'YYYY-MM-DD');
  
  const [startTime, setStartTime] = useState(getDefaultStartTime(selectedDate));
  const [endTime, setEndTime] = useState(getDefaultEndTime(selectedDate));

  const handleStartTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStartTime = e.target.value;
    setStartTime(newStartTime);

    // If end time is before start time, update end time to start time + 1 hour
    const startMoment = moment(selectedDate + ' ' + newStartTime);
    const endMoment = moment(selectedDate + ' ' + endTime);
    
    if (endMoment.isSameOrBefore(startMoment)) {
      const newEndMoment = startMoment.clone().add(1, 'hour');
      if (newEndMoment.hours() >= 23) {
        newEndMoment.hours(23).minutes(0);
      }
      setEndTime(newEndMoment.format('HH:mm'));
    }
  };

  const handleEndTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEndTime = e.target.value;
    const startMoment = moment(selectedDate + ' ' + startTime);
    const endMoment = moment(selectedDate + ' ' + newEndTime);
    
    if (endMoment.isAfter(startMoment)) {
      setEndTime(newEndTime);
    }
  };

  return (
    <>
      <div>
        <label className="block text-sm font-medium text-brand-lightgray mb-1 text-left">
          Activity Name *
        </label>
        <input
          type="text"
          name="name"
          required
          className="w-full p-2 bg-brand-fadegray/20 border border-brand-fadegray rounded-lg text-brand-lightgray"
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <TimeInput
          label="Start Time"
          name="startIsoTime"
          value={startTime}
          onChange={handleStartTimeChange}
          required
        />
        <TimeInput
          label="End Time"
          name="endIsoTime"
          value={endTime}
          onChange={handleEndTimeChange}
          required
        />
        <input 
          type="hidden" 
          name="startDate" 
          value={selectedDate}
        />
        <input 
          type="hidden" 
          name="endDate" 
          value={selectedDate}
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-brand-lightgray mb-1 text-left">
          Subtitle
        </label>
        <input
          type="text"
          name="subtitle"
          className="w-full p-2 bg-brand-fadegray/20 border border-brand-fadegray rounded-lg text-brand-lightgray"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-brand-lightgray mb-1 text-left">
          Description *
        </label>
        <textarea
          name="description"
          required
          rows={3}
          className="w-full p-2 bg-brand-fadegray/20 border border-brand-fadegray rounded-lg text-brand-lightgray"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-brand-lightgray mb-1 text-left">
          Location URL
        </label>
        <input
          type="url"
          name="location"
          className="w-full p-2 bg-brand-fadegray/20 border border-brand-fadegray rounded-lg text-brand-lightgray"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-brand-lightgray mb-1 text-left">
          Cover Image
        </label>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => onFileChange(e.target.files?.[0] || null)}
          className="w-full p-2 bg-brand-fadegray/20 border border-brand-fadegray rounded-lg text-brand-lightgray"
        />
      </div>
    </>
  );
};

export default ActivityFormFields;