import { Calendar, Camera, Clock, MapPin, MapPinned } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { EventViewMode } from '../events/EventView';
import { useQueryClient } from '@tanstack/react-query';
import { ToastContext } from '../Contexts';
import QuerySrvc from '../../services/queries/QuerySrvc';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/date';
import { getErrorStringFromObject } from '../../services/utils';

interface ActivityDetailsProps {
  activity: any;
  mode: EventViewMode;
}

const ActivityDetails = ({ activity, mode }: ActivityDetailsProps) => {
  const queryClient = useQueryClient();
  const toast = useContext(ToastContext);
  const updateActivityMutation = QuerySrvc.MUTATIONS.ACTIVITY.UPDATE_ACTIVITY(queryClient);
  const uploadImageMutation = QuerySrvc.MUTATIONS.ACTIVITY.UPLOAD_ACTIVITY_IMAGE(queryClient);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [startTime, setStartTime] = useState(moment.utc(activity?.startDateTime).format("HH:mm"));
  const [endTime, setEndTime] = useState(moment.utc(activity?.endDateTime).format("HH:mm"));
  const [showStartTimeInput, setShowStartTimeInput] = useState(false);
  const [showEndTimeInput, setShowEndTimeInput] = useState(false);
  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    if (uploadImageMutation.status === 'success') {
      toast?.showSuccessToast('Image uploaded successfully');
    } else if (uploadImageMutation.status === 'error') {
      toast?.showErrorToast('Failed to upload image');
    }
  }, [uploadImageMutation.status]);

  useEffect(() => {
    if (updateActivityMutation.status === 'success') {
      toast?.showSuccessToast('Activity updated successfully');
    } else if (updateActivityMutation.status === 'error') {
      toast?.showErrorToast(getErrorStringFromObject(updateActivityMutation.error) || 'Failed to update activity');
    }
  }, [updateActivityMutation.status]);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !activity.eventId) return;

    uploadImageMutation.mutate({
      eventId: activity.eventId,
      dayId: activity.programId,
      activityId: activity.id,
      file
    });
    fileInputRef.current && (fileInputRef.current.value = '');
  };

  const handleDragUpload = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files?.[0];
    if (!file || !activity.eventId) return;

    uploadImageMutation.mutate({
      eventId: activity.eventId,
      dayId: activity.programId,
      activityId: activity.id,
      file
    });
  }

  const triggerFileInput = (e: React.MouseEvent, activityId: string) => {
    e.stopPropagation();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const updateActivity = (update: any) => {
    if (!activity.eventId || !activity.programId || !activity.id) return;
    updateActivityMutation.mutate({
      eventId: activity.eventId,
      dayId: activity.programId,
      activityId: activity.id,
      payload: update
    });
  };

  const handleStartTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = moment.utc(activity.startDateTime); //, 'YYYY-MM-DD');

    const newStartTime = e.target.value;
    const [hour, minute] = newStartTime.split('T')[1].split(':');
    selectedDate.hours(parseInt(hour)).minutes(parseInt(minute));

    if (selectedDate.isBefore(moment.utc())) {
      toast?.showErrorToast('Start time cannot be in the past');
      return;
    }

    const endDateTime = moment.utc(activity.endDateTime);
    if (selectedDate.isAfter(endDateTime)) {
      let newEndDateTime = selectedDate.clone().add(1, 'hour');
      if (newEndDateTime.isAfter(moment.utc(selectedDate).endOf('day'))) {
        newEndDateTime = selectedDate.clone().set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 0
        });
      }
      updateActivity({ endIsoTime: newEndDateTime.toISOString(), startIsoTime: selectedDate.toISOString() });
      setEndTime(newEndDateTime.format("HH:mm"));
    } else {
      updateActivity({ startIsoTime: selectedDate.toISOString() });
    }
  };

  const handleEndTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = moment.utc(activity.endDateTime); //, 'YYYY-MM-DD');

    const newEndTime = e.target.value;
    const [hour, minute] = newEndTime.split('T')[1].split(':');
    selectedDate.hours(parseInt(hour)).minutes(parseInt(minute));

    if (selectedDate.isBefore(moment.utc())) {
      toast?.showErrorToast('End time cannot be in the past');
      return;
    }

    const startDateTime = moment.utc(activity.startDateTime);
    if (selectedDate.isBefore(startDateTime)) {
      let newStartDateTime = selectedDate.clone().subtract(1, 'hour');
      if (newStartDateTime.isBefore(moment.utc(selectedDate).startOf('day'))) {
        newStartDateTime = selectedDate.clone().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0
        });
      }
      updateActivity({ startIsoTime: newStartDateTime.toISOString(), endIsoTime: selectedDate.toISOString() });
      setStartTime(newStartDateTime.format("HH:mm"));
    } else {
      updateActivity({ endIsoTime: selectedDate.toISOString() });
    }

  };

  const emptyLocationString = 'Click To Add Location Url';


  return (
    <div className="flex flex-1 overflow-hidden activity-details">
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        accept="image/*"
        onChange={(e) => {
          handleImageUpload(e)
        }} />
      <div className="flex flex-1 py-10"
        onDragOver={(e) => {
          e.preventDefault();
          if (mode !== EventViewMode.MANAGE) {
            return;
          }
          setDragging(true);
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          if (mode !== EventViewMode.MANAGE) {
            return;
          }
          setDragging(false);
        }}
        onDrop={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (mode !== EventViewMode.MANAGE) {
            return;
          }
          setDragging(false);
          handleDragUpload(e as any);
        }}>
        {activity ? (
          <div className={`flex flex-1 flex-col relative overflow-hidden`}>
            <div className='flex-1 overflow-y-auto overflow-y-auto custom-scroll px-4 '>
              {
                <div


                  className={`relative flex w-full object-cover border border-brand-fadegray
                 aspect-video mb-4 justify-center bg-gradient-to-t from-brand-darkgray/0 via-brand-lightgray/30 to-brand-darkgray/0`}>

                  {
                    activity.image && <img
                      src={activity.image}
                      className="absolute w-full h-full object-cover rounded-lg" /> || <>
                      {
                        mode === EventViewMode.MANAGE && <Camera className={`w-6 h-6 drop-shadow-contrast-black self-center cursor-pointer z-40`}
                          onClick={(e) => {
                            e.stopPropagation();
                            triggerFileInput(e, activity.id)
                          }} />
                      }
                    </>
                  }
                </div>
              }
              <h3
                contentEditable={mode === EventViewMode.MANAGE}
                suppressContentEditableWarning={true}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    e.currentTarget.blur();
                  }
                }}
                onBlur={(e) => {
                  if (mode !== EventViewMode.MANAGE) {
                    return;
                  }
                  const content = e.target.innerText.trim();
                  content && content !== activity?.name ? updateActivity({ name: content }) : null;
                }}
                className={`text-2xl font-semibold text-brand-lightgray mb-6 text-left ${mode === EventViewMode.MANAGE ? 'cursor-text' : 'cursor-default'}`}>
                {activity.name}
              </h3>

              <div
                contentEditable={mode === EventViewMode.MANAGE}
                suppressContentEditableWarning={true}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    e.currentTarget.blur();
                  }
                }}
                onBlur={(e) => {
                  if (mode !== EventViewMode.MANAGE) {
                    return;
                  }
                  const content = e.target.innerText.trim();
                  content && content !== activity?.subtitle ? updateActivity({ subtitle: content }) : null;
                }}
                className={`text-lg font-semibold text-brand-lightgray custom-text-shadow mb-4 ${mode === EventViewMode.MANAGE ? 'cursor-text' : 'cursor-default'}`}>
                <span>{activity.subtitle}</span>
              </div>

              <div className="flex items-center gap-2 text-brand-lightgray">
                <div className="flex flex-row py-2 rounded-md text-brand-lightgray items-center gap-4 ">
                  <Calendar className="countdown font-mono w-5 h-5" />
                  <div className={`${mode === EventViewMode.MANAGE && 'hover:bg-brand-fadegray cursor-pointer'} px-4 rounded`}>
                    {
                      (showStartTimeInput && mode === EventViewMode.MANAGE) && <input
                        type="datetime-local"
                        name="startIsoTime"
                        defaultValue={moment.utc(activity.startDateTime).format("YYYY-MM-DDTHH:mm")}
                        min={moment.utc(activity.startDateTime).format("YYYY-MM-DDT00:00")}
                        max={moment.utc(activity.startDateTime).format("YYYY-MM-DDT23:59")}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            e.currentTarget.blur();
                          }
                        }}
                        onBlur={(e) => {
                          handleStartTimeChange(e);
                          setShowStartTimeInput(false)
                        }}
                        className="countdown font-mono font-bold mt-1"
                      /> ||
                      <>
                        <div className="countdown font-mono font-bold mt-1" onClick={() => {
                          setShowStartTimeInput(true)
                        }}>
                          <span style={{ "--value": moment.utc(activity.startDateTime).format("hh") } as React.CSSProperties}></span> :
                          <span style={{ "--value": moment.utc(activity.startDateTime).format("mm") } as React.CSSProperties}></span>
                        </div>
                        <span className="countdown font-mono font-bold mt-1">{moment.utc(activity.startDateTime).format("A")}</span>
                      </>
                    }
                  </div>

                  |
                  <div className={`${mode === EventViewMode.MANAGE && 'hover:bg-brand-fadegray cursor-pointer'} px-4 rounded`}>
                    {
                      (showEndTimeInput && mode === EventViewMode.MANAGE) && <input
                        type="datetime-local"
                        name="endIsoTime"
                        defaultValue={moment.utc(activity.endDateTime).format("YYYY-MM-DDTHH:mm")}
                        min={moment.utc(activity.endDateTime).format("YYYY-MM-DDT00:00")}
                        max={moment.utc(activity.endDateTime).format("YYYY-MM-DDT23:59")}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            e.currentTarget.blur();
                          }
                        }}
                        onBlur={(e) => {
                          handleEndTimeChange(e);
                          setShowEndTimeInput(false)
                        }}
                        className="countdown font-mono font-bold mt-1"
                      /> ||
                      <>
                        <div className="countdown font-mono font-bold mt-1" onClick={() => {
                          setShowEndTimeInput(true)
                        }}>
                          <span style={{ "--value": moment.utc(activity.endDateTime).format("hh") } as React.CSSProperties}></span> :
                          <span style={{ "--value": moment.utc(activity.endDateTime).format("mm") } as React.CSSProperties}></span>
                        </div>
                        <span className="countdown font-mono font-bold mt-1">{moment.utc(activity.endDateTime).format("A")}</span>
                      </>}
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-2 text-brand-lightgray ">
                <div className="flex flex-row py-2 rounded-md text-brand-lightgray items-center gap-4">
                  <Clock className="countdown font-mono w-5 h-5" />
                  <span className="countdown font-mono font-bold mt-1 px-4">
                    {`${parseFloat(moment.duration(moment.utc(activity.endDateTime).diff(moment.utc(activity.startDateTime))).asHours().toFixed(2))} hrs`}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 text-brand-lightgray">
                <div className="flex flex-row py-2 rounded-md text-brand-lightgray items-center gap-4">
                  <MapPin className={`countdown font-mono ${activity.location ? 'cursor-pointer' : ''} w-5 h-5`} onClick={() => {
                    activity.location && window.open(activity.location, '_blank')
                  }} />
                  <span
                    contentEditable={mode === EventViewMode.MANAGE}
                    suppressContentEditableWarning={true}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        e.currentTarget.blur();
                      }
                    }}
                    onBlur={(e) => {
                      if (mode !== EventViewMode.MANAGE) {
                        return;
                      }
                      const content = e.target.innerText.trim();
                      if (content === activity?.location || content === emptyLocationString) {
                        return;
                      } else if (content === '') {
                        return updateActivity({ location: content });
                      }
                      const isValidUrl = (string: string) => {
                        try {
                          new URL(string);
                          return true;
                        } catch (_) {
                          return false;
                        }
                      };

                      if (!isValidUrl(content)) {
                        toast?.showErrorToast('Invalid URL');
                        return;
                      }
                      updateActivity({ location: content });
                    }}
                    className="countdown font-mono text-sm font-bold mt-1 px-4">
                    {activity.location || emptyLocationString}
                  </span>
                </div>
              </div>

              <pre
                contentEditable={mode === EventViewMode.MANAGE}
                suppressContentEditableWarning={true}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    e.currentTarget.blur();
                  }
                }}
                onBlur={(e) => {
                  if (mode !== EventViewMode.MANAGE) {
                    return;
                  }
                  const content = e.target.innerText.trim();
                  content && content !== activity?.description ? updateActivity({ description: content }) : null;
                }}
                className={`text-sm font-sans whitespace-pre-line text-brand-lightgray my-6 ${mode === EventViewMode.MANAGE ? 'cursor-text' : 'cursor-default'}`}>
                {activity.description}
              </pre>

            </div>
          </div>
        ) : (
          <p className="text-sm text-brand-lightgray self-center mx-auto">Select an activity to see details</p>
        )}
      </div>
    </div>
  );
};

export default ActivityDetails;