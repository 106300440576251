import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../utils/api';
import { formatDate } from '../../utils/date';
import { getUserNameOrEmail, getProfilePic } from '../../services/utils';
import EventCard, { EventCardType } from './EventCard';

interface Event {
  id: string;
  name: string;
  startTime: string;
  image: string;
  owner: {
    firstName: string;
    lastName: string;
    emailId: string;
    profilePic: string;
  };
}

const HomeEventsList = () => {
  const navigate = useNavigate();
  const { data: events, isLoading, error } = useQuery<Event[]>({
    queryKey: ['events'],
    queryFn: () => ApiService.get('/api/events')
  });



  if (isLoading) return (
    <div className="flex-1 flex justify-center items-center p-4 md:p-8">
      <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-brand-lightgray"></div>
    </div>
  );

  if (error) return (
    <div className="flex-1 flex justify-center items-center p-4 md:p-8 text-brand-lightgray">
      Error loading events
    </div>
  );

  return (
    <div className="flex-1 overflow-y-auto bg-brand-darkgray custom-scroll">
      <div className="p-4 md:p-8 max-w-6xl mx-auto container">
        {/* <h2 className="text-2xl font-semibold text-brand-lightgray mb-6 text-center">Events</h2> */}
        <div className="grid grid-cols-1 gap-6 justify-items-center">
          {
            events?.map((event) => <EventCard key={event.id} event={event} cardType={EventCardType.HOME}/>)
          }
        </div>
      </div>
    </div>
  );
};

export default HomeEventsList;