import React from 'react';
import { ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';

interface BreadcrumbsProps {
  activeTab: string;
  eventName?: string;
  onBreadcrumbClick: (path: string) => void;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  activeTab,
  eventName,
  onBreadcrumbClick
}) => {

  return (   
    <div className="flex sticky p-4 py-2 -l-4 items-center top-0 z-40 breadcrumbs text-sm bg-brand-darkgray text-brand-lightgraybackup overflow-hidden">
      <ul>
        <li>
          Manage
        </li>
        <li>
          <Link className={` ${eventName ? 'text-brand-lightgraybackup' : 'text-brand-lightgray'}`} to={`/manage?activeTab=${activeTab}`}>
            {activeTab}
          </Link>
        </li>
        {
          eventName &&
          <li className='text-brand-lightgray'>
            {eventName}
          </li>
        }
      </ul>
    </div>
  );
};

export default Breadcrumbs;