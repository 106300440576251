import React from 'react';
import { Heart, MessageCircle } from 'lucide-react';
import { formatDate } from '../../utils/date';
import { getUserNameOrEmail, getProfilePic } from '../../services/utils';
import { Feed } from './types';
import FeedMedia from './FeedMedia';

interface FeedCardProps {
  feed: Feed;
}

const FeedCard: React.FC<FeedCardProps> = ({ feed }) => {
  const profilePic = getProfilePic(feed.owner?.profilePic);
  const username = getUserNameOrEmail(feed.owner);

  return (
    <div className="bg-brand-darkgray border border-brand-fadegray rounded-lg overflow-hidden w-full">
      {/* Feed Header */}
      <div className="p-4 flex items-center space-x-3">
        <div className="w-10 h-10 rounded-full overflow-hidden">
          <img
            src={profilePic}
            alt={username}
            className="w-full h-full object-cover"
          />
        </div>
        <div>
          <p className="font-semibold text-brand-lightgray">
            {username}
          </p>
          <p className="text-sm text-brand-fadegray">
            {formatDate(feed.createdAt, 'MMM DD, YYYY h:mm A')}
          </p>
        </div>
      </div>

      {/* Feed Media */}
      {feed.media && (
        <div className="mb-4">
          <FeedMedia media={feed.media} />
        </div>
      )}

      {/* Feed Content */}
      {feed.title && (
        <p className="px-4 pb-4 text-brand-lightgray">{feed.title}</p>
      )}

      {/* Feed Actions */}
      <div className="p-4 flex items-center space-x-6 border-t border-brand-fadegray">
        <button 
          className={`flex items-center space-x-2 transition-colors
            ${feed.selfLike ? 'text-red-500' : 'text-brand-lightgray hover:text-brand-lightgray/80'}`}
        >
          <Heart size={20} />
        </button>
        <button className="flex items-center space-x-2 text-brand-lightgray hover:text-brand-lightgray/80 transition-colors">
          <MessageCircle size={20} />
          <span>{feed.lastThreeComments.length}</span>
        </button>
      </div>

      {/* Comments */}
      {feed.lastThreeComments.length > 0 && (
        <div className="px-4 pb-4 space-y-3">
          {feed.lastThreeComments.map((comment) => (
            <div key={comment.id} className="flex items-start space-x-3">
              <div className="w-8 h-8 rounded-full overflow-hidden flex-shrink-0">
                <img
                  src={profilePic}
                  alt={username}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="flex-1">
                <p className="text-sm">
                  <span className="font-semibold text-brand-lightgray">
                    {username}
                  </span>
                  <span className="text-brand-lightgray ml-2">
                    {comment.text}
                  </span>
                </p>
                <p className="text-xs text-brand-fadegray mt-1">
                  {formatDate(comment.createdAt, 'MMM DD, YYYY h:mm A')}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FeedCard;