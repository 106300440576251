import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import HttpSrvc from '../HttpSrvc';
import { delay } from '../utils';

export const QUERIES = {
  EVENTS_GET: () => useQuery({
    queryKey: [EVENT_CONSTANTS.QUERY_KEYS.EVENTS],
    queryFn: () => {
      return HttpSrvc.get('/api/events');
    }
  })
};

export const MUTATIONS = {
  CREATE_EVENT: (queryClient?: QueryClient) => useMutation({
    mutationFn: (formData: FormData) => {
      return HttpSrvc.postFormData('/api/events', formData);
    },
    onSuccess: () => {
      queryClient?.invalidateQueries({ queryKey: [EVENT_CONSTANTS.QUERY_KEYS.EVENTS] });
    }
  }),

  UPDATE_EVENT: (queryClient?: QueryClient) => useMutation({
    mutationFn: ({ eventId, payload }: { eventId: string; payload: any }) => {
      return HttpSrvc.put(`/api/events/${eventId}`, payload);
    },
    onSuccess: (data: any) => {
      queryClient?.invalidateQueries({ queryKey: [EVENT_CONSTANTS.QUERY_KEYS.EVENTS] });
    }
  }),

  UPLOAD_EVENT_IMAGE: (queryClient?: QueryClient) => useMutation({
    mutationFn: async ({ eventId, file }: { eventId: string; file: File }) => {
      const formData = new FormData();
      formData.append('file', file);
      const response = HttpSrvc.postFormData(`/api/events/${eventId}/image`, formData);
      await delay(500); // adding delay so that CDN is ready to show the images.
      return response;
    },
    onSuccess: async (data: any) => {
      queryClient?.invalidateQueries({ queryKey: [EVENT_CONSTANTS.QUERY_KEYS.EVENTS] });
    }
  })
};

export const EVENT_CONSTANTS = {
  QUERY_KEYS: {
    EVENTS: 'EVENTS'
  }
}