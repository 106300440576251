import React from 'react';
import FeatureCard from './FeatureCard';
import documentsImage from "../../images/documents.webp";

const features = [
  {
    title: 'Event Itineraries',
    description: [
      'Create beautiful quick itineraries.',
      'Share with Guests'
    ],
    image: 'https://cdn.carbonpaper.app/program_images/5f93ac4e6510.contentMobile.png'
  },
  {
    title: 'Document Workflows',
    description: [
      'Create quick document workflows for your event.',
      'Track documents requested and received with ease.'
    ],
    image: documentsImage
  },
  {
    title: 'Guest Engagement',
    description: [
      'Keep guests informed of the event schedule, with automated notifications.',
      'Improve guest engagement with the social media space.'
    ],
    image: 'https://cdn.carbonpaper.app/program_images/446feacba4b6.contentMobile.png'
  }
];

const FeatureSection: React.FC = () => {
  return (
    <div className="flex flex-col lg:flex-row justify-evenly">
      {features.map((feature, index) => (
        <FeatureCard
          key={`feature-${index}`}
          {...feature}
          isLast={index === features.length - 1}
        />
      ))}
    </div>
  );
};

export default FeatureSection;