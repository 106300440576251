import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CurrentUserContext } from "./components/Contexts";
import Layout from "./components/Layout";
import App from "./App";
import Home from "./components/home/Home";
import EventView from "./components/events/EventView";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import ContactUs from "./components/ContactUs";
import ItineraryViewer from "./components/itinerary/ItineraryViewer";
import Manage from "./components/manage/Manage";
import { createRoot } from "react-dom/client";
import StorageSrvc from "./services/StorageSrvc";
import { STORAGE_SRVC_KEYS } from "./config/constants/AppStrings";
import QuerySrvc from "./services/queries/QuerySrvc";
import "./styles/styles.css";

const container = document.getElementById("approot");
const root = createRoot(container!);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

const AuthenticatedRoutes = () => {
  return (
    <Route element={<Layout />}>
      <Route path="" element={<Home />} />
      <Route path="events/:eventId" element={<EventView />} />
      <Route path="itinerary/:itineraryId/*" element={<ItineraryViewer />} />
      <Route path="manage" element={<Navigate to="/manage/events" replace />} />
      <Route path="manage/:tab" element={<Manage />} />
      <Route path="manage/events/:eventId" element={<Manage />} />
    </Route>
  );
};

const UnauthenticatedRoutes = () => {
  return (
    <Route element={<Layout />}>
      <Route path="itinerary/:itineraryId/*" element={<ItineraryViewer />} />
      <Route path="*" element={<App name="carbonpaper-web" />}/>
    </Route>
  );
};

const RouteComponent = () => {
  const [isReady, setIsReady] = useState(false);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [enableProfileTokenRefresh, setEnableProfileTokenRefresh] = useState(true);

  const { data: userProfile, isSuccess: isUserProfileFetchSuccess, 
    isError: isUserProfileFetchError, isFetching: isUserProfileFetching, 
    refetch: refetchUserProfile } = QuerySrvc.QUERIES.AUTH.REFRESH_USER_PROFILE_TOKEN(enableProfileTokenRefresh);

  useEffect(() => {
    if (isUserProfileFetching) {
      return;
    }
    if (isUserProfileFetchSuccess) {
      userProfile && userProfile?.authToken && setUserAndToken(userProfile);
    } else if (isUserProfileFetchError) {
      setEnableProfileTokenRefresh(false);
      setCurrentUser(null);
      setIsReady(true);
    }
  }, [isUserProfileFetching]);

  useEffect(() => {
    refetchUserProfile();
  }, []);

  const setUserAndToken = useCallback(async (currentUser: any) => {
    StorageSrvc.setItem(STORAGE_SRVC_KEYS.AUTH_TOKEN, currentUser.authToken);
    StorageSrvc.setItem(STORAGE_SRVC_KEYS.REFRESH_TOKEN, currentUser.refreshToken);
    setCurrentUser(currentUser);
    setIsReady(true);
  }, []);

  return (
    <CurrentUserContext.Provider value={{ currentUser, setCurrentUser, enableProfileTokenRefresh, setEnableProfileTokenRefresh }}>
      {isReady ? (
        <BrowserRouter>
          <Routes>
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-of-service" element={<TermsOfService />} />
            <Route path="contact-us" element={<ContactUs />} />
            {currentUser ? AuthenticatedRoutes() : UnauthenticatedRoutes() }
            
          </Routes>
        </BrowserRouter>
      ) : (
        <div className="fixed w-full h-full flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-brand-lightgray"></div>
        </div>
      )}
    </CurrentUserContext.Provider>
  );
};

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouteComponent />
    </QueryClientProvider>
  </React.StrictMode>
);