import React from 'react';
import { formatDate } from '../../utils/date';
import { getUserNameOrEmail, getProfilePic } from '../../services/utils';
import EventCard, { EventCardType } from '../home/EventCard';

interface Event {
  id: string;
  name: string;
  startTime: string;
  endTime: string;
  image: string;
  owner: {
    firstName: string;
    lastName: string;
    emailId: string;
    profilePic: string;
  };
}

interface EventGroupProps {
  events: Event[];
  onEventClick: (event: Event) => void;
}

const EventGroup: React.FC<EventGroupProps> = ({ events, onEventClick }) => {
  return (
    <div className='mb-32 p-4 overflow-auto custom-scroll'>      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 gap-y-12 justify-items-center">
        {events.map(event => (
          <EventCard 
            key={event.id} 
            event={event} 
            onEventClick={onEventClick} 
            cardType={EventCardType.MANAGE} 
          />
        ))}
      </div>
    </div>
  );
};

export default EventGroup;