import React from 'react';

interface FeedMediaProps {
  media: any;
}

const FeedMedia: React.FC<FeedMediaProps> = ({ media }) => {
  const isVideo = React.useMemo(() => {
    if (!media) return false;
    
    // Check file extension if type is not available
    return Boolean(media.match(/\.(mp4|webm|ogg)$/i));
    
    // Check media type if available
    // return media.type?.startsWith('video') || false;
  }, [media]);

  if (!media) return null;

  return (
    <div className="relative aspect-video w-full overflow-hidden bg-black">
      {isVideo ? (
        <video 
          className="w-full h-full object-contain"
          controls
          poster={media}
        >
          <source src={media} type={'video/mp4'} />
          Your browser does not support the video tag.
        </video>
      ) : (
        <img 
          src={media}
          alt="Feed content"
          className="w-full h-full object-contain"
        />
      )}
    </div>
  );
};

export default FeedMedia;