import { useMutation, useQuery } from '@tanstack/react-query';
import HttpSrvc from '../HttpSrvc';


export const QUERIES = {

};

export const MUTATIONS = {
  SEND_OTP: () => useMutation({
    mutationFn: (payload: any) => {
      return HttpSrvc.post(OPEN_APIS_CONSTANTS.SEND_OTP_API, payload)
    },
    onSuccess: (data: any) => {

    }
  }),
  VERIFY_OTP: () => useMutation({
    mutationFn: (payload: any) => {
      return HttpSrvc.post(OPEN_APIS_CONSTANTS.VERIFY_OTP_API, payload)
    },
    onSuccess: (data: any) => {

    }
  }),
  RESET_PASSWORD_BY_OTP: () => useMutation({
    mutationFn: (payload: any) => {
      return HttpSrvc.post(OPEN_APIS_CONSTANTS.RESET_PASSWORD_BY_OTP_API, payload)
    },
    onSuccess: (data: any) => {

    }
  }),
};

export const OPEN_APIS_CONSTANTS = {
  SEND_OTP_API: "/oapi/otp",
  VERIFY_OTP_API: "/oapi/otp/verify",
  RESET_PASSWORD_BY_OTP_API: "/oapi/resetpasswordbyotpverification"
};