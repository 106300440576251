import React from 'react';
import Drawer from './Drawer';
import HomeEventsList from './HomeEventsList';
import ChatPanel from './ChatPanel';

const Home = () => {
  return (
    <div className="flex h-full overflow-hidden">
      <div className="flex-1 flex flex-col md:flex-row">
        <HomeEventsList />
        {/* <ChatPanel /> */}
      </div>
    </div>
  );
};

export default Home;