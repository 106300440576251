import React from 'react';
import appStoreLogo from "../../images/ios_app_store.png";
import playStoreLogo from "../../images/android_app_store.png";

const AppDownloadSection: React.FC = () => {
  return (
    <div className="flex flex-row items-end w-96 self-center mt-12 mb-2">
      <div className="w-1/2 items-center flex cursor-pointer">
        <a  target='_blank'
          style={{ margin: 'auto' }}
          href="https://play.google.com/store/apps/details?id=com.carbonpaper.app&pcampaignid=web_share&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        >
          <img 
            style={{ height: '50px', margin: 'auto' }} 
            className="w-full h-1/2 flex justify-self-end" 
            src={playStoreLogo} 
            alt="Google Play Store Image" 
          />
        </a>
      </div>
      <div className="w-1/2 items-center flex cursor-pointer">
        <a  target='_blank'
          style={{ margin: 'auto' }}
          href="https://apps.apple.com/in/app/carbonpaper-app/id6477400555?ign-itscg=30200&ign-itsct=apps_box_promote_link"
        >
          <img 
            style={{ height: '50px', margin: 'auto' }} 
            className="w-full h-1/2 flex justify-self-end" 
            src={appStoreLogo} 
            alt="App Store Image" 
          />
        </a>
      </div>
    </div>
  );
};

export default AppDownloadSection;